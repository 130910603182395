.vinkaNavBar {
    position: relative;
    left: 0;
    top: 0;
    z-index: 11;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.72);
    backdrop-filter: blur(10px);
}

.vinkaBrand {
    font-weight: bold;
    color: #ffffff !important;
    /* Makes nav links and brand text on the same height */
    padding: 0 0 0 0 !important;
}

.vinkaBrand > span {
    transition: all 0.1s linear 0.01s;
}

.vinkaBrand > svg {
    transition: all 0.1s linear 0.01s;
    width: 6em;
    fill: #ffffff;
}

.vinkaBrand:hover {
    color: #808080ff !important;
    fill: #808080ff !important;
    cursor: pointer;
}

.vinkaBrand:hover > svg {
    fill: #808080ff !important;
}

.settingsIcon {
    fill: #fff;
    height: 1.5em;
    width: 1.5em;
    transition: all 0.1s linear 0.01s;
}

.settingsDropdown {
}

.settingsDropdownToggle {
    background-color: rgba(0, 0, 0, 0) !important;
    border-color: rgba(0, 0, 0, 0) !important;
}

.settingsDropdownToggle:hover > svg {
    fill: #808080ff;
}

.settingsDropdownToggle:focus {
    box-shadow: none !important;
}

.settingsDropdownMenu {
    border-radius: 0.5em;
    color: #dee2e6 !important;
    background-color: #343a40 !important;
    border-color: rgba(0, 0, 0, 0.15) !important;
    /*backdrop-filter: drop-shadow(4px 4px 10px blue);*/
}

.settingsDropdownItem {
    color: #ffffff !important;
}

.settingsDropdownItem:hover {
    background-color: rgba(255, 255, 255, 0.15) !important;
}

.settingsDropdownDivider {
    margin: 0.5rem 0 !important;
    padding-left: 1em !important;
    border-top: 1px solid rgba(0, 0, 0, 0.15) !important;
}
