.vinkaSuperContainer {
    padding: 0 0 !important;
    background: #d3d3d3;
    height: 100vh;
}

@media (min-width: 1800px) {
    .grid {
        width: 45%;
    }
}

@media (min-width: 992px) and (max-width: 1799.98px) {
    .grid {
        width: 75%;
    }
}

/* Small devices (landscape phones, 576px and up)*/
@media (max-width: 991.98px) {
    .grid {
        width: 100%;
    }
}
