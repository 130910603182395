.formContainer {
    background: white !important;
    padding: 1rem;
    border: 1px solid #b4b4b4;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.formGroup {
    margin-top: 1rem;
}

.separator {
    display: flex;
    align-items: center;
    text-align: left;
    margin-bottom: 1em;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000;
}

.separator:not(:empty)::before {
    margin-right: 0.25em;
}

.separator:not(:empty)::after {
    margin-left: 0.25em;
}
