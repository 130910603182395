.formContainer {
    background: white !important;
    padding: 1rem;
    border: 1px solid #b4b4b4;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.formGroup {
    margin-top: 1rem;
}
